<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">Thêm yêu cầu</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-autocomplete
              ref="inputIndex_0"
              @change="
                nextInputFocus(0);
                removeValidate('type');
                refreshCreateData();
              "
              :error="validates.includes('type')"
              v-model="createData.type"
              :items="createTypes"
              label="Phân loại *"
              dense
              outlined
              hide-details
              class="c-input-small"
            ></v-autocomplete>
          </v-col>

          <v-col cols="6">
            <v-autocomplete
              ref="inputIndex_1"
              @change="
                nextInputFocus(1);
                removeValidate('department');
              "
              :disabled="isDisableInputDepartment"
              :error="validates.includes('department')"
              v-model="createData.department"
              :items="createDepartments"
              label="Bộ phận *"
              dense
              outlined
              hide-details
              class="c-input-small"
            ></v-autocomplete>
          </v-col>

          <template v-if="createData.type">
            <v-col
              cols="12"
              v-if="[config.TYPE.PICKUP_REDUCE].includes(createData.type)"
            >
              <v-text-field
                ref="inputIndex_2"
                @keyup.enter="nextInputFocus(1)"
                @change="removeValidate('basket_code')"
                :error="validates.includes('basket_code')"
                label="Mã sọt *"
                dense
                outlined
                hide-details
                class="c-input-small"
                v-model="createData.basket_code"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              v-if="[config.TYPE.PICKUP_REDUCE].includes(createData.type)"
            >
              <v-text-field
                ref="inputIndex_3"
                @keyup.enter="nextInputFocus(2)"
                @change="removeValidate('cell_id')"
                :error="validates.includes('cell_id')"
                label="Vị trí nhặt *"
                dense
                outlined
                hide-details
                class="c-input-small"
                v-model="createData.cell_id"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              v-if="
                [
                  config.TYPE.RETURN_HAS_MONEY,
                  config.TYPE.UPDATE_ORDER_STATUS,
                  config.TYPE.RE_DELIVERY,
                  config.TYPE.URGING_DELIVERY,
                  config.TYPE.APPOINTMENT_DELIVERY,
                  config.TYPE.CANCEL_DELIVERY,
                  config.TYPE.INSPECTION_PACKING,
                  config.TYPE.ORDER_CHECK,
                  config.TYPE.ORDER_RETURN_CHECK,
                  config.TYPE.ORDER_UPDATE_INFO,
                  config.TYPE.ORDER_UPDATE_COD,
                ].includes(createData.type)
              "
            >
              <v-text-field
                ref="inputIndex_4"
                @keyup.enter="
                  nextInputFocus(3);
                  getOrder();
                "
                @change="removeValidate('order_tracking_id')"
                :error="validates.includes('order_tracking_id')"
                label="Tracking đơn *"
                dense
                outlined
                hide-details
                class="c-input-small"
                @blur="getOrder"
                v-model="createData.order_tracking_id"
              ></v-text-field>
            </v-col>

            <template
              v-if="[config.TYPE.ORDER_UPDATE_COD].includes(createData.type)"
            >
              <v-col cols="6">
                <vuetify-money
                  v-model.number="orderData.cash_on_delivery"
                  type="number"
                  :options="globalMoneyOptions"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  label="Yêu cầu thu hộ cũ"
                  disabled
                />
              </v-col>

              <v-col cols="6">
                <vuetify-money
                  v-model.number="orderData.request_new_cod"
                  ref="inputIndex_5"
                  type="number"
                  :options="globalMoneyOptions"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                  @change="removeValidate('money')"
                  :error="validates.includes('money')"
                  label="Số tiền cần thu hộ mới *"
                  placeholder="COD = 0 hoặc > 10,000"
                  @input="addMessageCod"
                />
              </v-col>
            </template>

            <v-col
              cols="12"
              v-if="
                [
                  config.TYPE.RETURN_HAS_MONEY,
                  config.TYPE.SALARY_ADVANCE,
                  config.TYPE.BONUS,
                  config.TYPE.PUNISHMENT,
                  config.TYPE.PRODUCT_REPAIR,
                ].includes(createData.type)
              "
            >
              <vuetify-money
                ref="inputIndex_5"
                @keyup.enter="nextInputFocus(4)"
                :options="globalMoneyOptions"
                @change="removeValidate('money')"
                :error="validates.includes('money')"
                label="Số tiền *"
                dense
                outlined
                hide-details
                class="c-input-small"
                v-model.number="createData.money"
              ></vuetify-money>
            </v-col>

            <v-col
              cols="12"
              v-if="
                [config.TYPE.BONUS, config.TYPE.PUNISHMENT].includes(
                  createData.type
                )
              "
            >
              <v-autocomplete
                ref="inputIndex_6"
                @change="nextInputFocus(5)"
                v-model="createData.id_identity_receipt"
                :items="employees"
                label="Chọn nhân sự *"
                dense
                outlined
                hide-details
                class="c-input-small"
              ></v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              v-if="
                [
                  config.TYPE.RECEIPT_INCREASE,
                  config.TYPE.RECEIPT_REDUCE,
                ].includes(createData.type)
              "
            >
              <v-text-field
                ref="inputIndex_7"
                @keyup.enter="nextInputFocus(5)"
                @change="removeValidate('receipt_tracking_id')"
                :error="validates.includes('receipt_tracking_id')"
                label="Tracking nhập kho *"
                dense
                outlined
                hide-details
                class="c-input-small"
                v-model="createData.receipt_tracking_id"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              v-if="
                [
                  config.TYPE.GOODS_INCREASE,
                  config.TYPE.GOODS_REDUCE,
                  config.TYPE.PICKUP_REDUCE,
                  config.TYPE.RECEIPT_INCREASE,
                  config.TYPE.RECEIPT_REDUCE,
                ].includes(createData.type)
              "
            >
              <v-text-field
                ref="inputIndex_8"
                @keyup.enter="nextInputFocus(6)"
                @change="removeValidate('sku')"
                :error="validates.includes('sku')"
                label="SKU *"
                dense
                outlined
                hide-details
                class="c-input-small"
                v-model="createData.sku"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              v-if="
                [
                  config.TYPE.GOODS_INCREASE,
                  config.TYPE.GOODS_REDUCE,
                  config.TYPE.PICKUP_REDUCE,
                  config.TYPE.RECEIPT_INCREASE,
                  config.TYPE.RECEIPT_REDUCE,
                ].includes(createData.type)
              "
            >
              <v-text-field
                ref="inputIndex_9"
                @keyup.enter="nextInputFocus(7)"
                @change="removeValidate('quantity')"
                :error="validates.includes('quantity')"
                label="Số lượng *"
                dense
                outlined
                hide-details
                class="c-input-small"
                v-model="createData.quantity"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              v-if="
                [
                  config.TYPE.LEAVE,
                  config.TYPE.TIMEKEEPING_ADDITIONAL,
                  config.TYPE.LEAVE_EARLY,
                ].includes(createData.type)
              "
            >
              <v-autocomplete
                ref="inputIndex_10"
                @change="
                  nextInputFocus(8);
                  removeValidate('working_shift');
                "
                :error="validates.includes('working_shift')"
                v-model="createData.working_shift"
                :items="workingShifts"
                label="Ca *"
                dense
                outlined
                hide-details
                class="c-input-small"
              ></v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              v-if="
                [
                  config.TYPE.LEAVE,
                  config.TYPE.TIMEKEEPING_ADDITIONAL,
                  config.TYPE.LEAVE_EARLY,
                ].includes(createData.type)
              "
            >
              <DatePicker
                label="Ngày *"
                name="working_date"
                @change="onWorkingDateChange"
                :min-date="minDate()"
                :disabled="
                  [
                    config.TYPE.TIMEKEEPING_ADDITIONAL,
                    config.TYPE.LEAVE_EARLY,
                  ].includes(createData.type)
                "
                dense
                outlined
                hide-details
                class="c-input-small"
              />
            </v-col>

            <v-col cols="12">
              <v-textarea
                ref="inputIndex_11"
                @keyup.enter="nextInputFocus(9)"
                @change="removeValidate('note')"
                :error="validates.includes('note')"
                label="Ghi chú"
                v-model="createData.note"
                dense
                outlined
                hide-details
                class="c-input-small"
              ></v-textarea>
            </v-col>

            <v-col cols="12">
              <v-file-input
                accept="image/*"
                v-model="file"
                @change="onInputFileChange"
                append-icon="mdi-paperclip"
                prepend-icon=""
                :error="validates.includes('file')"
                dense
                outlined
                hide-details
                class="c-input-small"
              ></v-file-input>
            </v-col>
          </template>
        </v-row>
      </v-container>
      <small v-if="errMsg" class="red--text">{{ errMsg }}</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
      <v-btn color="primary" @click="saveInternalRequest">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { compress } from "image-conversion";
import { httpClient } from "@/libs/http";
import {
  INTERNAL_REQUEST_DEFAULT,
  INTERNAL_REQUEST_BONUS,
  INTERNAL_REQUEST_PUNISHMENT,
  ALLOW_REQUEST_CREATE_TYPES,
} from "@/libs/const";
import moment from "moment";

export default {
  name: "Create",
  components: {
    DatePicker: () => import("@/components/common/DatePicker"),
  },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    types: {
      type: Array,
      default: () => [],
    },
    departments: {
      type: Array,
      default: () => [],
    },
    workingShifts: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    errMsg: null,
    createData: { ...INTERNAL_REQUEST_DEFAULT },
    orderData: {},
    validates: [],
    file: null,
    fileUrl: null,
    isLoading: false,
    employees: [],
  }),
  computed: {
    createTypes() {
      let types = [...this.types].filter((t) =>
        ALLOW_REQUEST_CREATE_TYPES.includes(t.value)
      );
      if (!this.checkPermission(["super_admin"])) {
        types = [...types].filter(
          (t) =>
            !t.text.includes("Auto") &&
            ![INTERNAL_REQUEST_BONUS, INTERNAL_REQUEST_PUNISHMENT].includes(
              t.value
            )
        );
      }
      return types;
    },
    createDepartments() {
      return [...this.departments];
    },
    typeToDepartment() {
      return (this.config && this.config.TYPE_TO_DEPARTMENT) || {};
    },
    isDisableInputDepartment() {
      return (
        !!this.typeToDepartment[this.createData.type] || !this.createData.type
      );
    },
  },
  mounted() {
    this.getEmployees();
  },
  methods: {
    async getEmployees() {
      const { data } = await httpClient.post("/employee-active-my-pos", {
        id_warehouse: this.id_warehouse,
      });
      this.employees = data.map((e) => ({
        value: e.id_identity,
        text: e.full_name || "",
      }));
    },
    async saveInternalRequest() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      this.errMsg = null;
      this.validates = [];
      try {
        await httpClient.post("/internal-request-create", {
          ...this.createData,
          file: (this.file && this.fileUrl) || "",
        });
        this.createData = { ...INTERNAL_REQUEST_DEFAULT };
        this.closeDialog();
        this.refreshData();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        const validates =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.validates) ||
          [];
        if (validates && validates.length > 0) {
          validates.forEach((v) => {
            this.validates.push(v.key);
          });
        }
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    refreshData() {
      this.$emit("refreshData");
    },
    refreshCreateData() {
      this.createData = {
        ...INTERNAL_REQUEST_DEFAULT,
        type: this.createData.type,
        department: this.typeToDepartment[this.createData.type],
      };
      this.validates = [];
      this.errMsg = null;
    },
    removeValidate(name) {
      this.validates = this.validates.filter((v) => v !== name);
    },
    nextInputFocus(index) {
      if (index > 10) {
        return false;
      }
      this.$nextTick(() => {
        if (this.$refs[`inputIndex_${index + 1}`]) {
          this.$refs[`inputIndex_${index + 1}`].focus();
        } else {
          this.nextInputFocus(index + 1);
        }
      });
    },
    onInputFileChange(file) {
      if (file) {
        this.uploadFile(file);
      }
    },
    async uploadFile(file) {
      const resBlob = await compress(file, {
        quality: 0.85,
        type: "image/jpeg",
      });
      const newFile = new File(
        [resBlob],
        `${moment().unix()}_${this.generateRandomString(10)}.jpg`,
        { type: "image/jpeg" }
      );
      let fd = new FormData();
      fd.append("file", newFile);
      httpClient
        .post(`/upload-image`, fd)
        .then((res) => {
          this.fileUrl = res.data;
        })
        .catch((err) => {
          console.log("uploadFile err", err);
        });
    },
    onWorkingDateChange(date) {
      this.createData.working_date = date;
    },
    minDate() {
      return moment().format("YYYY-MM-DD");
    },

    async getOrder() {
      if (!this.createData.order_tracking_id) {
        return false;
      }
      try {
        const { data } = await httpClient.post("/order-online-by-code", {
          code: this.createData.order_tracking_id,
        });
        this.isLoading = false;
        if (!data || data.length === 0) {
          this.$vToastify.error(
            `Không tìm thấy đơn này với mã ${this.createData.order_tracking_id}`
          );
          this.createData = { ...this.createData, order_tracking_id: null };
          return false;
        }
        const order = data[0];
        this.orderData = { ...order };
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.createData = { ...this.createData, tracking_id: null };
      }
    },

    addMessageCod() {
      this.createData = {
        ...this.createData,
        money: this.orderData.request_new_cod,
        note: `Yêu cầu sửa COD từ ${this.formatNumber(
          this.orderData.cash_on_delivery
        )} thành ${this.formatNumber(this.orderData.request_new_cod)}`,
      };
    },
  },
};
</script>

<style scoped></style>
